export default {
  tableItems: [
    {
      prop: 'id',
      label: 'ID',
      width: '100px',
      classname: 'text-blue'
    },
    {
      prop: 'code',
      label: 'Code',
      classname: ''
    },
    {
      prop: 'name',
      label: 'Diagnosis',
      classname: ''
    },
    {
      prop: 'category',
      label: 'Category',
      classname: ''
    }
  ],
  tableActions: [
    {
      label: 'Edit',
      url: '/resources/diagnosis/update',
      type: 'jump'
    }
  ],
  pageActions: [
    {
      label: 'Add',
      url: '/resources/diagnosis/update',
      type: 'jump'
    }
  ]
}